<template>
  <v-dialog v-model="dialog.display" :max-width="500">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-text-field
          :label="$i18n.t('rate-plans.fields.name.title')"
          :placeholder="$i18n.t('rate-plans.fields.name.placeholder')"
          v-model="form.name"
          @input="delayTouch($v.form.name)"
          @blur="$v.form.name.$touch"
          :error-messages="nameErrors"
        ></v-text-field>
        <v-textarea
          v-model="form.description"
          no-resize
          rows="2"
          :label="$i18n.t('rate-plans.fields.description.title')"
          :placeholder="$i18n.t('rate-plans.fields.description.placeholder')"
          @input="delayTouch($v.form.description)"
          @blur="$v.form.description.$touch"
          :error-messages="descriptionErrors"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";

export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  methods: {
    getPath() {
      return !this.dialog.update ? "rate-plans.create" : "rate-plans.update";
    },
    resetForm() {
      this.$v.form.$reset();
    },
    getMessages() {
      return {
        200: () => {
          return this.dialog.success;
        },
        201: () => {
          return this.dialog.success;
        },
        403: true,
        404: true,
        400: true,
        422: (error) => {
          const self = this;
          return self.$i18n.t(`rate-plans.errors.${error.code}`);
        },
      };
    },
  },
  validations() {
    const validations = {
      form: {
        name: {
          required,
          maxLength: maxLength(40),
        },
        description: {
          required,
          maxLength: maxLength(255),
        },
      },
    };
    return validations;
  },
  computed: {
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;
      if (!this.$v.form.name.required)
        errors.push(this.$i18n.t("rate-plans.fields.name.required"));
      if (!this.$v.form.name.maxLength)
        errors.push(
          this.$i18n.t("rate-plans.fields.name.max_length", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;
      if (!this.$v.form.description.required)
        errors.push(this.$i18n.t("rate-plans.fields.description.required"));
      if (!this.$v.form.description.maxLength)
        errors.push(
          this.$i18n.t("rate-plans.fields.description.max_length", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );
      return errors;
    },
  },
};
</script>