<template>
  <v-dialog max-width="400" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-select
          :placeholder="$t('services.channel.fields.type.placeholder')"
          :label="$t('services.channel.fields.type.title')"
          :items="channelTypes"
          v-model="form.type"
          @input="delayTouch($v.form.type)"
          @blur="$v.form.type.$touch"
          :error-messages="typeErrors"
        ></v-select>
        <v-text-field
          :label="$t('rate-plans.channel-pricing.fields.price.title')"
          :placeholder="
            $t('rate-plans.channel-pricing.fields.price.placeholder')
          "
          v-model="form.price"
          @input="delayTouch($v.form.price)"
          @blur="$v.form.price.$touch"
          :error-messages="priceErrors"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, helpers, minValue } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  validations() {
    const validations = {
      form: {
        type: {
          required,
          matchType: (val) =>
            !helpers.req(val) || this.$utils.matchChannelType(val),
        },
        price: {
          required,
          minValue: minValue(0),
        },
      },
    };
    return validations;
  },
  data: () => ({
    loading: {
      save: false,
    },
  }),
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    getPath() {
      return !this.form.id
        ? "rate-plans-channel-type.create"
        : "rate-plans-channel-type.update";
    },
    getMessages() {
      const self = this;
      return {
        200: this.dialog.success,
        201: this.dialog.success,
        403: true,
        400: true,
        500: true,
        404: this.$t("rate-plans.channel-pricing.errors.404"),
        422: (error) => {
          if (error.code === "E100") {
            return self.$t("rate-plans.errors.404");
          } else if (error.code === "E101") {
            return self.$t("rate-plans.errors.E101");
          } else if (error.code === "E102") {
            return self.$t("rate-plans.channel-pricing.errors.E102");
          }
        },
      };
    },
    getData(form) {
      const { id, price, ratePlanId, ...channelPricing } = form;
      if (this.form.id) {
        return { ...channelPricing, ratePlanChannelTypeId: id, price: parseInt(price) };
      } else {
        return { ...channelPricing, ratePlanId: ratePlanId, price: parseInt(price) };
      }
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
    },
    resetForm() {
      this.$v.form.$reset();
    },
  },
  computed: {
    ...mapGetters({ channelTypes: "channel/channelTypes" }),
    priceErrors() {
      const errors = [];

      if (!this.$v.form.price.$dirty) return errors;
      if (!this.$v.form.price.required)
        errors.push(
          this.$i18n.t("rate-plans.channel-pricing.fields.price.required")
        );
      if (!this.$v.form.price.minValue)
        errors.push(
          this.$i18n.t("rate-plans.channel-pricing.fields.price.invalid")
        );
      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.form.type.$dirty) return errors;
      if (!this.$v.form.type.required)
        errors.push(this.$i18n.t("services.channel.fields.type.required"));
      if (!this.$v.form.type.matchType)
        errors.push(this.$i18n.t("services.channel.fields.type.invalid"));
      return errors;
    },
  },
};
</script>