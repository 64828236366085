<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">
          {{
          $t("rate-plans.channel-pricing.title")
          }}
        </span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2 mb-0">
        <v-text-field
          readonly
          dense
          :value="ratePlan.name"
          :label="$t('rate-plans.fields.name.title2')"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <template v-if="!loading.channelPricing">
          <v-list class="pt-0">
            <v-list-item
              v-for="channel in channelsPricing"
              class="px-0"
              :key="`content-${channel.id}`"
            >
              <v-list-item-icon class="mr-3" v-if="$utils.hasProperty(avatar, channel.type)">
                <v-icon>{{ avatar[channel.type].icon.value }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-bold pt-2">
                <v-list-item-title>{{ channel.type }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-2 grey--text text--darken-1">
                  {{
                  $tc("services.fields.balance.format", channel.price, {
                  count: $utils.pad(channel.price, 2),
                  })
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <template v-if="$permission.$can('rate-plan-channel-type.update', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editChannelPricing(channel)"
                          color="primary"
                          small
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.edit") }}</span>
                    </v-tooltip>
                  </template>
                  <template v-if="$permission.$can('rate-plan-channel-type.delete', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteChannelPricing(channel)"
                          :loading="loading.delete && item.id === channel.id"
                          small
                          color="danger"
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.delete") }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <div class="text-center mt-2 mb-3">
            <v-progress-circular indeterminate color="secondary" width="4" size="40"></v-progress-circular>
          </div>
        </template>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0" v-if="$permission.$can('rate-plan-channel-type.create', ['user'])">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="addChannelPricing()">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t("rate-plans.channel-pricing.btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <channel-pricing-form
      @success="successSaveChannelPricingForm()"
      :dialog="dialogForm"
      :form="form"
    ></channel-pricing-form>
    <channel-pricing-delete-confirm
      @confirm="confirmDeleteChannelPricing"
      :dialog="dialogDeleteChannelPricingConfirm"
    ></channel-pricing-delete-confirm>
  </v-dialog>
</template>

<script>
import ChannelPricingDeleteConfirm from "./../commons/confirm";
import ChannelPricingForm from "./channel-pricing-form";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {};
      }
    },
    ratePlan: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: () => ({
    channelsPricing: [],
    item: {},
    loading: {
      channelPricing: false,
      delete: false
    },
    dialogForm: {
      display: false,
      title: null,
      success: null
    },
    dialogDeleteChannelPricingConfirm: {
      display: false,
      message: null
    },
    form: {
      id: null,
      type: null,
      price: null,
      ratePlanId: null
    }
  }),
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    async getChannelsPrincing() {
      this.loading.channelPricing = true;
      try {
        const response = await this.request({
          url: `rate-plans-channel-type.list?filter=ratePlanId||$eq||${this.ratePlan.id}`,
          messages: { 500: true }
        });
        this.channelsPricing = response.data;
      } catch (error) {
        //empty
      }
      this.loading.channelPricing = false;
    },
    addChannelPricing() {
      for (const prop in this.form) {
        if (prop === "ratePlanId") {
          this.form["ratePlanId"] = this.ratePlan.id;
          continue;
        }
        this.form[prop] = null;
      }
      this.dialogForm = {
        display: true,
        title: this.$t("rate-plans.channel-pricing.add.title"),
        success: this.$t("rate-plans.channel-pricing.add.success")
      };
    },
    editChannelPricing(channelPricing) {
      for (const prop in channelPricing) {
        if (this.$utils.hasProperty(this.form, prop)) {
          this.form[prop] = channelPricing[prop];
        }
      }
      this.dialogForm = {
        display: true,
        title: this.$t("rate-plans.channel-pricing.edit.title"),
        success: this.$t("rate-plans.channel-pricing.edit.success")
      };
    },
    deleteChannelPricing(channelPricing) {
      this.dialogDeleteChannelPricingConfirm = {
        display: true,
        message: this.$t("rate-plans.channel-pricing.delete.confirm")
      };
      this.item = channelPricing;
    },
    async confirmDeleteChannelPricing() {
      if (!this.item.id) return;
      this.loading.delete = true;
      this.notify({ status: false });
      try {
        await this.request({
          url: "rate-plans-channel-type.delete",
          method: "POST",
          data: { ratePlanChannelTypeId: this.item.id },
          messages: {
            403: true
          }
        });
        this.getChannelsPrincing();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    successSaveChannelPricingForm() {
      this.dialogForm.display = false;
      this.getChannelsPrincing();
    }
  },
  watch: {
    "dialog.display"(val) {
      if (val) {
        this.getChannelsPrincing();
      }
    }
  },
  computed: {
    ...mapGetters({ avatar: "conversation/avatar" })
  },
  components: {
    ChannelPricingForm,
    ChannelPricingDeleteConfirm
  }
};
</script>