<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">{{ $i18n.t("rate-plans.title") }}</div>
    <v-card class="rounded-md">
      <v-progress-linear indeterminate color="primary" width="2" size="60" v-if="loading.list"></v-progress-linear>
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn depressed @click="refreshItems()" :loading="loading.refresh" class="mr-2">
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="filterItems()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $i18n.t("btn.filter") }}
        </v-btn>
        <div class="ml-auto" v-if="$permission.$can('rate-plan.get', ['user'])">
          <v-btn color="primary" @click="addRatePlan()">
            <v-icon left>mdi-credit-card-plus-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card elevation="0" color="rgba(255,255,255,0)">
      <v-data-iterator
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :loading="loading.list"
        sort-by="name"
        sort-desc
        loading-text
      >
        <template v-slot:default="props">
          <v-row>
            <v-col v-for="ratePlan in props.items" :key="ratePlan.id" cols="12" sm="6" md="4">
              <v-hover v-slot="{ hover }">
                <v-card>
                  <v-card-title
                    :title="ratePlan.name"
                    class="text-body-1 font-weight-bold"
                    style="height: 65px"
                  >
                    <span>{{ ratePlan.name }}</span>
                    <v-spacer></v-spacer>
                    <template v-if="$permission.$can('rate-plan.update', ['user'])">
                      <v-btn
                        v-if="ratePlan.name !== 'default'"
                        @click="editRatePlan(ratePlan)"
                        color="primary"
                        small
                        icon
                        v-show="hover"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-if="$permission.$can('rate-plan.delete', ['user'])">
                      <v-btn
                        v-if="ratePlan.name !== 'default'"
                        color="danger"
                        @click="deleteRatePlan(ratePlan)"
                        small
                        icon
                        :loading="loading.delete && ratePlan.id === item.id"
                        v-show="hover"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-card-title>

                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("rate-plans.fields.created.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">
                          {{
                          $moment(ratePlan.createdAt).format("YYYY-MM-DD")
                          }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("services.fields.name.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">{{ ratePlan.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <template v-if="$permission.$can('rate-plan-channel-type.get', ['user'])">
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            @click="channelPricing(ratePlan)"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            small
                          >
                            <v-icon>mdi-wan</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("rate-plans.btn.channel_pricing") }}</span>
                      </v-tooltip>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>

    <rate-plan-form
      v-if="$permission.$can('rate-plan.create', ['user']) || $permission.$can('rate-plan.update', ['user'])"
      @success="saveRatePlanSuccess"
      :form="form"
      :item="item"
      :dialog="dialog.form"
    ></rate-plan-form>
    <rate-plan-delete
      v-if="$permission.$can('rate-plan.delete', ['user'])"
      @confirm="confirmDeleteRatePlan"
      :dialog="dialog.delete"
    ></rate-plan-delete>
    <rate-plan-filter
      @filter="applyFilter"
      :configuration="filter.ratePlan"
      :dialog="dialog.filter"
    ></rate-plan-filter>
    <channel-pricing v-if="$permission.$can('rate-plan-channel-type.get', ['user'])" :rate-plan="item" :dialog="dialog.channelPricing"></channel-pricing>
  </div>
</template>

<script>
import RatePlanDelete from "./../commons/confirm";
import ChannelPricing from "./channel-pricing";
import RatePlanFilter from "./../commons/filter";
import ListMixin from "./../../mixins/list";
import RatePlanForm from "./form";

export default {
  created() {
    this.setFilterRatePlan();
  },
  mixins: [ListMixin],
  data: () => ({
    path: "rate-plans.list",
    form: {
      ratePlanId: null,
      name: null,
      description: null
    },
    item: {},
    filters: {},
    filter: {
      ratePlan: []
    },
    dialog: {
      form: {
        display: false,
        title: null,
        success: null,
        update: false
      },
      filter: {
        display: false,
        title: null,
        maxWidth: 500
      },
      channelPricing: {
        display: false
      },
      delete: {
        display: false,
        message: null
      }
    }
  }),
  methods: {
    filterItems() {
      this.dialog.filter.display = true;
      this.dialog.filter.title = this.$i18n.t("rate-plans.filter.title");
    },
    setFilterRatePlan() {
      this.filter.ratePlan = [
        {
          name: "name",
          type: {
            name: "VTextField",
            operator: "$contL",
            options: {
              label: this.$i18n.t("rate-plans.fields.name.title"),
              placeholder: this.$i18n.t("rate-plans.fields.name.placeholder"),
              clearable: true
            }
          }
        }
      ];
    },
    addRatePlan() {
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("rate-plans.add.title"),
        display: true,
        success: this.$i18n.t("rate-plans.add.success"),
        update: false
      });
      this.resetForm();
    },
    editRatePlan(item) {
      this.item = item;
      for (const prop in this.form) {
        if (prop === "ratePlanId") {
          this.form.ratePlanId = item.id;
        } else {
          this.form[prop] = this.$utils.getPropertyValue(item, prop);
        }
      }
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("rate-plans.edit.title"),
        display: true,
        success: this.$i18n.t("rate-plans.edit.success"),
        update: true
      });
    },
    deleteRatePlan(ratePlan) {
      this.item = ratePlan;
      this.dialog.delete = {
        display: true,
        message: this.$t("rate-plans.delete.confirm")
      };
    },
    async confirmDeleteRatePlan() {
      const self = this;
      if (!this.item.id) return;
      this.loading.delete = true;
      this.notify({ status: false });
      try {
        await this.request({
          url: "rate-plans.delete",
          method: "POST",
          data: { ratePlanId: this.item.id },
          messages: {
            403: true,
            422: error => {
              if (error.code === "E100") {
                return self.$t("rate-plans.errors.deleteE100");
              } else if (error.code === "E101") {
                return self.$t("rate-plans.errors.deleteE101");
              }
            }
          }
        });
        this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    saveRatePlanSuccess() {
      this.refreshItems();
      this.dialog.form.display = false;
      if (this.dialog.form.update) return;
      this.resetForm();
    },
    resetForm() {
      for (const prop in this.form) {
        this.form[prop] = null;
      }
    },
    channelPricing(item) {
      this.dialog.channelPricing.display = true;
      this.item = item;
    }
  },
  components: {
    RatePlanForm,
    RatePlanFilter,
    ChannelPricing,
    RatePlanDelete
  }
};
</script>