<template>
  <div>
    <rate-plan-list></rate-plan-list>
  </div>
</template>


<script>
import RatePlanList from './../../components/rate-plans/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('rate-plans.title'),
    };
  },
  components: {
    RatePlanList
  }
}
</script>